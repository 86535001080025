<template>
  <div>
    <h1 class="mb-6">Enter Your New Password</h1>

    <v-form v-model="isValid" ref="form" @submit.prevent="createPassword">
      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :rules="[rules.required, rules.min]"
        label="Password"
        name="Password"
      ></v-text-field>

      <v-text-field
        v-model="password_confirmation"
        :append-icon="showPasswordC ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswordC ? 'text' : 'password'"
        @click:append="showPasswordC = !showPasswordC"
        :rules="[rules.required, rules.min, rules.match]"
        label="Confirm Password"
      ></v-text-field>

      <Button
        block
        large
        class="my-10"
        @click="createPassword"
        :loading="loading.user"
        :disabled="!isValid"
        type="submit"
        >Save</Button
      >
    </v-form>
  </div>
</template>

<script>
import { Button } from "@/components";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");

export default {
  name: "reset-password",
  components: { Button },
  data() {
    return {
      password: "",
      password_confirmation: "",
      showPassword: false,
      showPasswordC: false,
      isValid: false,
      rules: {
        required: value => !!value || "Field is required.",
        min: v => v.length >= 8 || "Min 8 characters",
        match: v => v === this.password || "Password do not match"
      }
    };
  },
  computed: {
    ...mapState(["loading"])
  },
  methods: {
    ...mapActions(["RESET_PASSWORD"]),
    createPassword() {
      const { email } = this.$route.query;
      const { password, password_confirmation } = this;
      this.RESET_PASSWORD({
        email,
        password,
        password_confirmation
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.email) {
      next("/forgot-password");
    } else {
      next();
    }
  }
};
</script>

<style scoped></style>
